import LiveImg from '../assets/images/sportsbook/icons/liveIcon.svg';
import { format } from 'date-fns';

import CricketImg from '../assets/images/sportsbook/cricket-inplay.png';
import FootBallImg from '../assets/images/sportsbook/football-inplay.png';
import TennisImg from '../assets/images/sportsbook/tennis-inplay.png';
import HorseInplay from '../assets/images/sportsbook/horse-inplay.png';
import GreyHoundImg from '../assets/images/sportsbook/greyhound-live.png';
import BasketballImg from '../assets/images/sportsbook/basketball-inplay.png';
import BaseballImg from '../assets/images/sportsbook/baseball-live.png';
import MegaFishing from '../assets/images/sportsbook/Frame 212.png';
import RummyJili from '../assets/images/sportsbook/Frame 213.png';
import DragonTiger from '../assets/images/sportsbook/Frame 214.png';

import CricketIcon from '../assets/images/home/tiles/Cricket-2.svg';
import TennisIcon from '../assets/images/home/tiles/Tennis.svg';
import FootballIcon from '../assets/images/home/tiles/Football.svg';
import HorseRacingIcon from '../assets/images/home/tiles/horseracing-icon.svg';

import Basketball from '../assets/images/sportsbook/BasketBall.svg';
import Baseball from '../assets/images/sportsbook/BaseBall.svg';
import GreyHound from '../assets/images/sportsbook/GreyHound.svg';

//new icon
import NewCricketImg from '../assets/images/sportsbook/new-inplay-icons/cricket.svg';
import NewFootBallImg from '../assets/images/sportsbook/new-inplay-icons/football.svg';
import NewTennisImg from '../assets/images/sportsbook/new-inplay-icons/tennis.svg';
import NewHorseInplay from '../assets/images/sportsbook/new-inplay-icons/horse.svg';
import NewGreyHoundImg from '../assets/images/sportsbook/new-inplay-icons/greyhound.svg';
import NewBasketballImg from '../assets/images/sportsbook/new-inplay-icons/baseketball.svg';
import NewBaseballImg from '../assets/images/sportsbook/new-inplay-icons/baseball.svg';
import JSEncrypt from 'jsencrypt';
import { RsaPublicKey } from '../constants/RSAPublicKey';

export const getEncreptedPwd = (password) => {
  let RSAEncrypt = new JSEncrypt({ default_key_size: '1024' });
  RSAEncrypt.setPublicKey(RsaPublicKey);
  return RSAEncrypt.encrypt(password);
};

export function similarity(s1: string, s2: string) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }

  return (longerLength - editDistance(longer, shorter)) / longerLength;
}

export function getLangIdByName(type: string) {
  return LangIdByName[type];
}

export const LangIdByName: { [key: string]: string } = {
  cricket: '200003',
  football: '200004',
  tennis: '200005',
  horseracing: '200006',
  greyhound: '200007',
  basketball: '200008',
  baseball: '200009',
  badminton: '200003',
};

function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs: number[] = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export const OutcomeDescByEnumMap = {
  'Lost': 'Lost',
  'Won': 'Win',
  'Open': 'Unsettled',
  '11': 'Unsettled',
  'RolledBack': 'Rolled Back',
  'Voided': 'Void',
  'Lapse': 'Lapsed',
  'Cancelled': 'Cancelled',
};

export const MarketTypeByEnumMap: { [key: string]: string } = {
  'MATCH_ODDS': 'Match odds',
  'BOOKMAKER': 'Bookmaker',
  'FANCY': 'Fancy',
  'PREMIUM': 'Premium',
  'CASINO': 'Casino',
  'BINARY': 'Binary',
  'INDIAN_CASINO': 'Indian Casino',
};

export function getOutcomeDescByEnumName(eventType: string) {
  return OutcomeDescByEnumMap[eventType];
}

export const ThousandFormatter = (num: any) => {
  if (num > 999) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toFixed(1);
  }
};

export const _findIndexByArray = (array: any, value: any) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === value) {
      return i;
    }
  }
};

export function formatDate(date: Date) {
  try {
    return format(date, 'dd-MM-yy hh:mm a');
  } catch (err) {
    console.log(date, err);
    return date;
  }
}

export function formatTime(date: Date) {
  try {
    return format(date, 'HH:mm');
  } catch (err) {
    console.log(date, err);
    return date;
  }
}

export const liveImagesMap = {
  '1': LiveImg,
  '2': LiveImg,
  '4': LiveImg,
  '7': LiveImg,
  '4339': LiveImg,
  '7522': LiveImg,
  '7511': LiveImg,
};

export const SportIconMap = {
  '1': FootBallImg,
  '2': TennisImg,
  '4': CricketImg,
  '7': HorseInplay,
  '4339': GreyHoundImg,
  '7522': BasketballImg,
  '7511': BaseballImg,
};

export const SportIconMapInplay = {
  '1': NewFootBallImg,
  '2': NewTennisImg,
  '4': NewCricketImg,
  '7': NewHorseInplay,
  '4339': NewGreyHoundImg,
  '7522': NewBasketballImg,
  '7511': NewBaseballImg,
};

const SportIdByNameMap: { [key: string]: string } = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  horse_racing: '7',
  horseracing: '7',
  grey_hound: '4339',
  greyhoundracing: '4339',
  basketball: '7522',
  baseball: '7511',
};

export const SportIdByName: { [key: string]: string } = {
  '1': 'Football',
  '2': 'Tennis',
  '4': 'Cricket',
  '7': 'HorseRacing',
  '4339': 'GreyHound',
  '7522': 'BasketBall',
  '7511': 'BaseBall',
};

export const OutcomeDescMap = {
  '0': 'Lost',
  '1': 'Win',
  '2': 'Unsettled',
  '11': 'Unsettled',
  '3': 'Rolled Back',
  '4': 'Void',
  '5': 'Lapsed',
  '6': 'Cancelled',
};

export const IndianCasinoMarketNameMap: { [key: string]: string } = {
  '0': 'Lucky 7B',
  '1': 'Lucky 7A',
  '2': 'Teenpatti',
  '3': 'Dragon Tiger 2020',
  '4': 'Dragon Tiger Lion',
  '5': 'Bollywood Casino AAA',
  '6': 'Race 2020',
  '7': '32 Card',
  '8': '3Card Judgement',
  '9': 'Andar Bahar',
};

export const MarketTypeMap: { [key: string]: string } = {
  '0': 'Match odds',
  '1': 'Book maker',
  '2': 'Fancy',
  '3': 'Premium Market',
  '4': 'Casino',
  '5': 'Binary',
  '6': 'Indian Casino',
};

export const TransactionTypeMap = {
  '0': 'Deposit',
  '1': 'Withdraw',
  '2': 'Settlement Deposit',
  '3': 'Settlement Withdraw',
  '4': 'Bet Placement',
  '5': 'Bet Settlement',
  '6': 'Rollback Bet Settlement',
  '7': 'Void Bet Settlement',
  '8': 'Casino Bet Placement',
  '9': 'Casino Bet Settlement',
  '10': 'Casino Bet Rollback',
  '11': 'Win Commission',
  '12': 'Win Commission Reversal',
  '13': 'Indian Casino Bet Placement',
  '14': 'Indian Casino Bet Settlement',
  '15': 'Commission Claim',
  '16': 'Commission Deposit',
  '17': 'Commission Rollback',
  '18': 'Commission Void',
  '19': 'Casino Bet Placement',
  '20': 'Casino Bet Settlement',
  '21': 'Casino Rollback',
  '22': '-',
  '23': 'Binary Bet Placement',
  '24': 'Binary Bet Settlement',
  '25': 'Binary Bet Rollback',
  '26': 'Bet Placement',
  '27': 'Bet Settlement',
  '28': 'Bet Rollback',
  '29': 'Bet Void',
  '30': 'Bet Cancelled',
  '31': 'Bet Expired',
  '32': 'Bet Lapsed',
  '33': 'Bet Deleted',
  '34': 'Risk Update',
  '35': 'Binary Bet Void',
  '36': 'Sport Book Bet Placement',
  '37': 'Sport Book Bet Settlement',
  '38': 'sport Book Bet Rollback',
  '39': 'Rolling Commission',
  '40': 'Win Commission Update',
  '41': 'Win Commission Update',
  '42': 'Rollback Void Bet Settlement',
  '44': 'Commission Claim',
};

export const getBetTimeoutByMarket: { [key: string]: number } = {
  FANCY: 1,
  MO: 1,
  BM: 1,
  BINARY: 1,
  CASINO: 1,
  OT: 1,
};

export function getTransactionNameByID(trans: string) {
  return TransactionTypeMap[trans];
}

export function getOutcomeDescName(eventType: string) {
  return OutcomeDescMap[eventType];
}

export function getSportIdByName(eventType: string) {
  return SportIdByNameMap[eventType];
}

export function MarketTypeByID(id: string) {
  return MarketTypeMap[id] ? MarketTypeMap[id] : 'Casino';
}

export function DisabledBinaryEvents() {
  return ['NIFTY BANK', 'NIFTY 50'];
}

export const wrapData = (value: string, upto: number = 4) => {
  if (value.length <= upto) {
    return value;
  }
  return value.slice(0, upto) + '****';
};

export const SportMarketMap: { [key: string]: string } = {
  'MATCH ODDS': 'MT0.0',
  'OVER/UNDER 0.5 GOALS': 'MT0.5',
  'OVER/UNDER 1.5 GOALS': 'MT1.5',
  'OVER/UNDER 2.5 GOALS': 'MT2.5',
};

export const OfflineTransactionTypeMap: { [key: string]: number } = {
  BANK_TRANSACTION: 0,
  UPI_PHONE_PE: 1,
  UPI_GPAY: 2,
  PAYTM: 3,
};

export const OfflineTransactionStatusTypeMap: { [key: string]: number } = {
  IN_PROGRESS: 0,
  ACCEPTED: 1,
  DECLINED: 2,
};

export function getSportNameByIdMap(eventType: string) {
  return SportNameByIdMap[eventType];
}

export const SportNameByIdMap: { [key: string]: string } = {
  '1': 'soccer',
  '2': 'tennis',
  '4': 'cricket',
  '7522': 'basketball',
  '7511': 'baseball',
  '4339': 'greyhoundracing',
  '7': 'horseracing',
};

export enum Jwt_token {
  ACCOUNT_ID = 'aid',
  HOUSE_ID = 'hid',
  SAP_TOKEN = 'sap',
  CURRENCY_TYPE = 'cur',
  PERMISSION = 'perm',
  ROLE = 'role',
  SUBJECT_NAME = 'sub',
  OWNER_NAME = 'owner',
  ACCOUNT_PATH = 'path',
  AFFILIATE_PATH = 'affPath',
}

export enum Role {
  SDM = 'Super-Duper-Master',
  DM = 'Duper-Master',
  SM = 'Super-Master',
  AGENT = 'Agent',
  USER = 'User',
}

export const sidebarImgMap = {
  tennis: TennisIcon,
  football: FootballIcon,
  soccer: FootballIcon,
  cricket: CricketIcon,
  horseracing: HorseRacingIcon,
  greyhound: GreyHound,
  basketball: Basketball,
  baseball: Baseball,
}

export const demoUserPrefix = 'zzzzsysdemo';

export const capitalize = (str: string) => {
  const words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]?.toUpperCase()) {
      words[i] = words[i][0]?.toUpperCase() + words[i].substring(1);
    }
  }
  return words.join(' ');
};

export const transactionTypesMap = {
  BET_PLACEMENT: 'Bet Placement',
  BET_SETTLEMENT: 'Bet Settlement',
  BINARY_BET_PLACEMENT: 'Binary Bet Placement',
  BINARY_BET_ROLLBACK: 'Binary Bet Rollback',
  BINARY_BET_SETTLEMENT: 'Binary Bet Settlement',
  BINARY_BET_VOID: 'Binary Bet Void',
  BONUS_REDEEMED: 'Bonus Redeemed',
  BONUS_ROLLED_BACK: 'Bonus Rolled Back',
  CASINO_BET_PLACEMENT: 'Casino Bet Placement',
  CASINO_BET_ROLLBACK: 'Casino Bet Rollback',
  CASINO_BET_SETTLEMENT: 'Casino Bet Settlement',
  CLAIM_INCENTIVE: 'Claim Incentive',
  COMMISSION_CLAIM: 'Commission Claim',
  COMMISSION_DEPOSIT: 'Commission Deposit',
  COMMISSION_ROLLBACK: 'Commission Rollback',
  COMMISSION_VOID: 'Commission Void',
  DEPOSIT: 'Deposit',
  GAP_BET_PLACEMENT: 'Gap Bet Placement',
  GAP_BET_ROLLBACK: 'Gap Bet Rollback',
  GAP_BET_SETTLEMENT: 'Gap Bet Settlement',
  INCENTIVE_TRANSFER: 'Incentive Transfer',
  INDIAN_CASINO_BET_PLACEMENT: 'Indian Casino Bet Placement',
  INDIAN_CASINO_BET_SETTLEMENT: 'Indian Casino Bet Settlement',
  ROLLBACK_BET_SETTLEMENT: 'Rollback Bet Settlement',
  ROLLBACK_VOID_BET_SETTLEMENT: 'Rollback Void Bet Settlement',
  ROLLBACK_WIN_COMMISSION: 'Rollback Win Commission',
  ROLLING_COMMISSION: 'Rolling Commission',
  ROLLING_COMMISSION_ROLLBACK: 'Rolling Commission Rollback',
  SAP_BET_CANCELLED: 'SAP Bet Cancelled',
  SAP_BET_DELETED: 'SAP Bet Deleted',
  SAP_BET_EXPIRED: 'SAP Bet Expired',
  SAP_BET_LAPSED: 'SAP Bet Lapsed',
  SAP_BET_PLACEMENT: 'SAP Bet Placement',
  SAP_BET_ROLLBACK: 'SAP Bet Rollback',
  SAP_BET_SETTLEMENT: 'SAP Bet Settlement',
  SAP_BET_VOID: 'SAP Bet Void',
  SAP_UPDATE_RISK: 'SAP Update Risk',
  SETTLEMENT_DEPOSIT: 'Settlement Deposit',
  SETTLEMENT_WITHDRAW: 'Settlement Withdraw',
  SPORT_BOOK_BET_PLACEMENT: 'Sport Book Bet Placement',
  SPORT_BOOK_BET_ROLLBACK: 'Sport Book Bet Rollback',
  SPORT_BOOK_BET_SETTLEMENT: 'Sport Book Bet Settlement',
  UN_KNOWN_TYPE: 'Unknown Type',
  VOID_BET_SETTLEMENT: 'Void Bet Settlement',
  WIN_COMMISSION: 'Win Commission',
  WIN_COMMISSION_UPDATE: 'Win Commission Update',
  WITHDRAW: 'Withdraw',
};

export const transactionTypes = [
  { value: 'BET_PLACEMENT', name: 'Bet Placement' },
  { value: 'BET_SETTLEMENT', name: 'Bet Settlement' },
  { value: 'BINARY_BET_PLACEMENT', name: 'Binary Bet Placement' },
  { value: 'BINARY_BET_ROLLBACK', name: 'Binary Bet Rollback' },
  { value: 'BINARY_BET_SETTLEMENT', name: 'Binary Bet Settlement' },
  { value: 'BINARY_BET_VOID', name: 'Binary Bet Void' },
  { value: 'BONUS_REDEEMED', name: 'Bonus Redeemed' },
  { value: 'BONUS_ROLLED_BACK', name: 'Bonus Rolled Back' },
  { value: 'CASINO_BET_PLACEMENT', name: 'Casino Bet Placement' },
  { value: 'CASINO_BET_ROLLBACK', name: 'Casino Bet Rollback' },
  { value: 'CASINO_BET_SETTLEMENT', name: 'Casino Bet Settlement' },
  { value: 'CLAIM_INCENTIVE', name: 'Claim Incentive' },
  { value: 'COMMISSION_CLAIM', name: 'Commission Claim' },
  { value: 'COMMISSION_DEPOSIT', name: 'Commission Deposit' },
  { value: 'COMMISSION_ROLLBACK', name: 'Commission Rollback' },
  { value: 'COMMISSION_VOID', name: 'Commission Void' },
  { value: 'DEPOSIT', name: 'Deposit' },
  { value: 'GAP_BET_PLACEMENT', name: 'Gap Bet Placement' },
  { value: 'GAP_BET_ROLLBACK', name: 'Gap Bet Rollback' },
  { value: 'GAP_BET_SETTLEMENT', name: 'Gap Bet Settlement' },
  { value: 'INCENTIVE_TRANSFER', name: 'Incentive Transfer' },
  { value: 'INDIAN_CASINO_BET_PLACEMENT', name: 'Indian Casino Bet Placement' },
  {
    value: 'INDIAN_CASINO_BET_SETTLEMENT',
    name: 'Indian Casino Bet Settlement',
  },
  { value: 'ROLLBACK_BET_SETTLEMENT', name: 'Rollback Bet Settlement' },
  {
    value: 'ROLLBACK_VOID_BET_SETTLEMENT',
    name: 'Rollback Void Bet Settlement',
  },
  { value: 'ROLLBACK_WIN_COMMISSION', name: 'Rollback Win Commission' },
  { value: 'ROLLING_COMMISSION', name: 'Rolling Commission' },
  { value: 'ROLLING_COMMISSION_ROLLBACK', name: 'Rolling Commission Rollback' },
  { value: 'SAP_BET_CANCELLED', name: 'SAP Bet Cancelled' },
  { value: 'SAP_BET_DELETED', name: 'SAP Bet Deleted' },
  { value: 'SAP_BET_EXPIRED', name: 'SAP Bet Expired' },
  { value: 'SAP_BET_LAPSED', name: 'SAP Bet Lapsed' },
  { value: 'SAP_BET_PLACEMENT', name: 'SAP Bet Placement' },
  { value: 'SAP_BET_ROLLBACK', name: 'SAP Bet Rollback' },
  { value: 'SAP_BET_SETTLEMENT', name: 'SAP Bet Settlement' },
  { value: 'SAP_BET_VOID', name: 'SAP Bet Void' },
  { value: 'SAP_UPDATE_RISK', name: 'SAP Update Risk' },
  { value: 'SETTLEMENT_DEPOSIT', name: 'Settlement Deposit' },
  { value: 'SETTLEMENT_WITHDRAW', name: 'Settlement Withdraw' },
  {
    value: 'SPORT_BOOK_BET_PLACEMENT',
    name: 'Sport Book Bet Placement',
  },
  {
    value: 'SPORT_BOOK_BET_ROLLBACK',
    name: 'Sport Book Bet Rollback',
  },
  {
    value: 'SPORT_BOOK_BET_SETTLEMENT',
    name: 'Sport Book Bet Settlement',
  },
  {
    value: 'UN_KNOWN_TYPE',
    name: 'Unknown Type',
  },
  {
    value: 'VOID_BET_SETTLEMENT',
    name: 'Void Bet Settlement',
  },
  {
    value: 'WIN_COMMISSION',
    name: 'Win Commission',
  },
  {
    value: 'WIN_COMMISSION_UPDATE',
    name: 'Win Commission Update',
  },
  {
    value: 'WITHDRAW',
    name: 'Withdraw',
  },
];

export const SPToBFIdMap = {
  'sr:sport:1': '1',
  'sr:sport:5': '2',
  'sr:sport:21': '4',
  'sr:sport:2': '7522',
  'sr:sport:3': '7511',
};

export const BFToSRIdMap = {
  '1': 'sr:sport:1',
  '2': 'sr:sport:5',
  '4': 'sr:sport:21',
  '7522': 'sr:sport:2',
  '7511': 'sr:sport:3',
};

const allowedRedirectionUrls = [
  '/exchange_sports/inplay',
  '/exchange_sports/inplay',
  '/exchange_sports/tennis',
  '/exchange_sports/football',
];

export const isValidLink = (data: any, history: any) => {
  if (!data.redirectionUrl || data.redirectionUrl === 'nourl') return;

  if (allowedRedirectionUrls.includes(data.redirectionUrl))
    history.push(data.redirectionUrl);

  window.open(data.redirectionUrl, '_blank');
};

export const demoUser = () => {
  return getFieldFromToken(JwtToken.SUBJECT_NAME)?.startsWith(demoUserPrefix);
};

export const notDemoUser = () => {
  return !demoUser();
};

export enum JwtToken {
  ACCOUNT_ID = 'aid',
  HOUSE_ID = 'hid',
  SAP_TOKEN = 'sap',
  CURRENCY_TYPE = 'cur',
  PERMISSION = 'perm',
  ROLE = 'role',
  SUBJECT_NAME = 'sub',
  OWNER_NAME = 'owner',
  ACCOUNT_PATH = 'path',
}

export const getFieldFromToken = (value: JwtToken) => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token')?.split('.')[1];
    return JSON.parse(window.atob(claim))[value];
  }
  return null;
};

export const fancyCategories = [
  'sessions',
  'wpmarket',
  'extramarket',
  'oddeven',
];

export const capitalizeWord = (str: string) => {
  const words = str?.split(' ');
  for (let i = 0; i < words?.length; i++) {
    if (words?.[i]?.trim().length > 0) {
      words[i] = words?.[i]?.[0]?.toUpperCase() + words?.[i]?.substring(1);
    }
  }

  return words.join(' ');
};
